import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'UserAdmin',
    loadChildren: () => import('../generated/routes/UserAdmin.module').then((m) => m.UserAdminModule)
  },
  {
    path: 'UserClient',
    loadChildren: () => import('../generated/routes/UserClient.module').then((m) => m.UserClientModule)
  },
  {
    path: 'UserClientGroup',
    loadChildren: () => import('../generated/routes/UserClientGroup.module').then((m) => m.UserClientGroupModule)
  },
  {
    path: 'ImportingFile',
    loadChildren: () => import('../generated/routes/ImportingFile.module').then((m) => m.ImportingFileModule)
  },
  {
    path: 'AvailableAiModels',
    loadChildren: () => import('../generated/routes/AvailableAiModels.module').then((m) => m.AvailableAiModelsModule)
  },
  {
    path: 'ExternalTagMapper',
    loadChildren: () => import('../generated/routes/ExternalTagMapper.module').then((m) => m.ExternalTagMapperModule)
  },
  {
    path: 'Tag',
    loadChildren: () => import('../generated/routes/Tag.module').then((m) => m.TagModule)
  },
  {
    path: 'TagType',
    loadChildren: () => import('../generated/routes/TagType.module').then((m) => m.TagTypeModule)
  },
  {
    path: 'Assistant',
    loadChildren: () => import('../generated/routes/Assistant.module').then((m) => m.AssistantModule)
  },
  {
    path: 'CopywriterStyleRecord',
    loadChildren: () => import('../generated/routes/CopywriterStyleRecord.module').then((m) => m.CopywriterStyleRecordModule)
  },
  {
    path: 'LlmAction',
    loadChildren: () => import('../generated/routes/LlmAction.module').then((m) => m.LlmActionModule)
  },
  {
    path: 'DocumentalisteSearch',
    loadChildren: () => import('../generated/routes/DocumentalisteSearch.module').then((m) => m.DocumentalisteSearchModule)
  },
  {
    path: 'CreateDocumentWord',
    loadChildren: () => import('../generated/routes/CreateDocumentWord.module').then((m) => m.CreateDocumentWordModule)
  },
  {
    path: 'CreateDocumentExcel',
    loadChildren: () => import('../generated/routes/CreateDocumentExcel.module').then((m) => m.CreateDocumentExcelModule)
  },
  {
    path: 'Copywriter',
    loadChildren: () => import('../generated/routes/Copywriter.module').then((m) => m.CopywriterModule)
  },
  {
    path: 'Deepl',
    loadChildren: () => import('../generated/routes/Deepl.module').then((m) => m.DeeplModule)
  },
  {
    path: 'DocumentAnalystDiscovery',
    loadChildren: () => import('../generated/routes/DocumentAnalystDiscovery.module').then((m) => m.DocumentAnalystDiscoveryModule)
  },
  {
    path: 'DocumentDataAnalyst',
    loadChildren: () => import('../generated/routes/DocumentDataAnalyst.module').then((m) => m.DocumentDataAnalystModule)
  },
  {
    path: 'adaptivLog',
    loadChildren: () => import('../generated/routes/adaptivLog.module').then((m) => m.adaptivLogModule)
  },
  {
    path: 'ExternalTagTypeMapper',
    loadChildren: () => import('../generated/routes/ExternalTagTypeMapper.module').then((m) => m.ExternalTagTypeMapperModule)
  },
  {
    path: 'Graph',
    loadChildren: () => import('../generated/routes/Graph.module').then((m) => m.GraphModule)
  },
  {
    path: 'OpenAiChat',
    loadChildren: () => import('../generated/routes/OpenAiChat.module').then((m) => m.OpenAiChatModule)
  },
  {
    path: 'ImageToVideo',
    loadChildren: () => import('../generated/routes/ImageToVideo.module').then((m) => m.ImageToVideoModule)
  },
  {
    path: 'Creative',
    loadChildren: () => import('../generated/routes/Creative.module').then((m) => m.CreativeModule)
  },
  {
    path: 'AudioToChunks',
    loadChildren: () => import('../generated/routes/AudioToChunks.module').then((m) => m.AudioToChunksModule)
  },
  {
    path: 'VideoToChunks',
    loadChildren: () => import('../generated/routes/VideoToChunks.module').then((m) => m.VideoToChunksModule)
  }
  /*{
    path: 'FlowLLMAction',
    loadChildren: () => import('../generated/routes/FlowLLMAction.module').then((m) => m.FlowLLMActionModule)
  }*/
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: []
})
export class DataModule {}
